
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useBillingStore } from '../../../../store/stores/billing'
import { useProject } from '../../../../hooks/useProject'

export default defineComponent({
    props: {},
    setup(props, { emit }) {
        const billingStore = useBillingStore()
        const { project } = useProject()
        const items = [
            { name: 'ЗА МЕСЯЦ', value: '1' },
            { name: 'ЗА 3 МЕСЯЦА (-10%)', value: '3' },
            { name: 'ЗА ГОД (-25%)', value: '12' },
        ]
        const currentTariff = computed(() => billingStore.getCurrentTariff(project.value.id))
        const update = (value: string) => {
            billingStore.selectedSubscriptionPeriod = value
        }
        onMounted(() => {
            if (currentTariff.value?.subscriptionPeriod) {
                billingStore.selectedSubscriptionPeriod = currentTariff.value.subscriptionPeriod
            } else {
                billingStore.selectedSubscriptionPeriod = '1'
            }
        })
        return { items, update, billingStore }
    },
})
