
import { useProject } from '@/hooks/useProject'
import useRouter from '@/hooks/useRouter'
import SubscriptionPeriodSelect from '@/modules/marketplace/components/billing/SubscriptionPeriodSelect.vue'
import TariffCard from '@/modules/marketplace/components/billing/TariffCard.vue'
import { defineComponent, onMounted, ref } from 'vue'
import { useBillingStore } from '../../../../../store/stores/billing'
import TariffIntegrationsTable from '../TariffIntegrationsTable.vue'
import TariffRadioGroup from '../TariffRadioGroup.vue'
export default defineComponent({
    components: { TariffCard, TariffRadioGroup, TariffIntegrationsTable, SubscriptionPeriodSelect },
    props: {},
    setup(props, { emit }) {
        const { project } = useProject()
        const { route } = useRouter()
        const billingStore = useBillingStore()
        const loading = ref(true)
        onMounted(async () => {
            if (billingStore.getCurrentTariff(project.value.id)) return
            loading.value = true
            await Promise.all([
                billingStore.fetchCurrentTariff(project.value.id),
                billingStore.fetchTariffs(),
            ])

            loading.value = false
        })
        return { billingStore }
    },
})
