
import { computed, defineComponent, ref, watch } from 'vue'
import { useBillingStore } from '../../../../store/stores/billing'
import { useProject } from '../../../../hooks/useProject'
import { useUser } from '@/hooks/useUser'
import api from '../../../../api/api'
import SpinnerLoader from '../../../../components/SpinnerLoader.vue'
import { declOfNum, openInCurrentTab, openInNewTab } from '../../../../utils'

export default defineComponent({
    components: { SpinnerLoader },
    props: {},
    setup(props, { emit }) {
        const { root } = props as any
        const billingStore = useBillingStore()
        const { project } = useProject()
        const loading = ref(true)
        const paymentLinkLoading = ref(false)
        const solvedPaymentData = ref<any>()
        const paymentPayloadId = ref<number>()
        const { user } = useUser()

        const modalOpened = computed({
            get: () => billingStore.paymentModal.opened,
            set: (v: boolean) => billingStore.setPaymentModalOpened(v),
        })
        const currentTariffData = computed(() => billingStore.getCurrentTariff(project.value.id))
        const selectedTariff = computed(() => billingStore.getTariffById(billingStore.selectedTariffId!)!)
        const formattedCurrentTariffActiveUntil = computed(() =>
            new Date(currentTariffData.value.activeUntil).toLocaleDateString()
        )
        const formattedUnusedDiscount = computed(() => {
            if (solvedPaymentData.value.price?.unusedDiscount) {
                return billingStore.formatPrice(solvedPaymentData.value.price?.unusedDiscount)
            }
        })
        const currentTariffFormattedPrice = computed(() => {
            if (!currentTariffData.value) return '0 ₽'
            return billingStore.formatPrice(
                billingStore.getTarrifRegularPrice(
                    currentTariffData.value.id,
                    currentTariffData.value.subscriptionPeriod
                )
            )
        })
        const refundDescription = computed(() => {
            if (solvedPaymentData.value.price?.toRefund) {
                return `${billingStore.formatPrice(
                    solvedPaymentData.value.price?.toRefund
                )} вернется вам на карту после обращения в техническую поддержку`
            }
        })
        const selectedTariffFormattedFirstPaymentPrice = computed(() =>
            billingStore.formatPrice(
                selectedTariff.value.complexPrice.monthPeriod[billingStore.selectedSubscriptionPeriod!]
                    .firstPayment.sum
            )
        )
        const formattedResultPrice = computed(() =>
            billingStore.formatPrice(solvedPaymentData.value.price?.toPay)
        )
        const selectedTariffPaymentDescription = computed(() => {
            const firstPayment =
                selectedTariff.value.complexPrice.monthPeriod[billingStore.selectedSubscriptionPeriod!]
                    .firstPayment
            const firstPaymentIsSpecial =
                firstPayment.months !== Number(billingStore.selectedSubscriptionPeriod)
            if (firstPaymentIsSpecial) {
                const regularPaymentFormattedDate = new Date(
                    solvedPaymentData.value.price?.regularPayAfter
                ).toLocaleDateString()
                return `Первое списание за ${firstPayment.months} ${declOfNum(firstPayment.months, [
                    'месяц',
                    'месяца',
                    'месяцев',
                ])}, далее с ${regularPaymentFormattedDate} ежемесячно по ${billingStore.formatPrice(
                    solvedPaymentData.value.price?.regularPay
                )}`
            }
            return
        })
        const formattedTodayDate = computed(() => new Date().toLocaleDateString())
        watch(modalOpened, async v => {
            if (v) {
                await initData()
            }
        })

        const closeModal = () => {
            billingStore.setPaymentModalOpened(false)
        }
        const initData = async () => {
            loading.value = true
            const { data, error } = await api.marketplace.solvePayment({
                projectId: project.value.id,
                marketplaceIntegrationId: selectedTariff.value.id,
                subscriptionPeriod: billingStore.selectedSubscriptionPeriod!,
                userId: user.value.sub,
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка при загрузке данных о платеже')
                loading.value = false
                return
            }
            solvedPaymentData.value = data
            paymentPayloadId.value = (data as any).paymentPayloadId
            loading.value = false
        }

        const makePayment = async () => {
            paymentLinkLoading.value = true
            const { data, error } = await api.marketplace.installIntegration({
                paymentPayloadId: paymentPayloadId.value,
                integrationId: selectedTariff.value.id,
                projectId: project.value.id,
                subscriptionPeriod: billingStore.selectedSubscriptionPeriod!,
            } as any)
            console.log({ data })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка при попытке оплаты')
                paymentLinkLoading.value = false
                return
            }
            if (data?.status === 'needPayment') {
                openInCurrentTab(data.paymentLink)
            }
            paymentLinkLoading.value = false
        }

        return {
            billingStore,
            modalOpened,
            selectedTariff,
            formattedTodayDate,
            loading,
            selectedTariffFormattedFirstPaymentPrice,
            selectedTariffPaymentDescription,
            currentTariffData,
            formattedCurrentTariffActiveUntil,
            formattedUnusedDiscount,
            refundDescription,
            closeModal,
            formattedResultPrice,
            makePayment,
            paymentLinkLoading,
        }
    },
})
