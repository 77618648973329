
import { computed, defineComponent, ref } from 'vue'
import { useProject } from '../../../../../hooks/useProject'
import { useBillingStore } from '../../../../../store/stores/billing'
import { useConfirmStore } from '../../../../../store/stores/confirm'
export default defineComponent({
    props: {},
    setup(props, { emit }) {
        const billingStore = useBillingStore()
        const confirmStore = useConfirmStore()
        const { project } = useProject()
        const currentTariff = computed(() => billingStore.getCurrentTariff(project.value.id))
        const currentTariffState = computed(() => billingStore.getCurrentTariffState(project.value.id))
        const unsubscribeLoading = ref(false)
        const tarriffName = computed(() =>
            currentTariff.value ? currentTariff.value.name : 'Тариф не подключен'
        )
        const subscriptionPrice = computed(() =>
            currentTariff.value.subscriptionId
                ? billingStore.formatPrice(
                      currentTariff.value.complexPrice.monthPeriod[currentTariff.value.subscriptionPeriod]
                          .regularPayment.sum
                  )
                : ''
        )

        const subscriptionIsActive = computed(
            () => currentTariff.value?.subscriptionId && !currentTariff.value?.subscriptionDeletedAt
        )
        const subscriptionState = computed(() => {
            if (!subscriptionIsActive.value) {
                return 'Подписка не активна'
            }
            const formattedDate = new Date(currentTariff.value.nextPayment).toLocaleDateString()
            return `Спишется ${formattedDate}`
        })
        const openPaymentModal = () => {
            billingStore.setPaymentModalOpened(true)
        }
        const goToProject = () => {
            //  не используем роутер, так как нам нужна именно перезагрузка страницы
            const projectId = project.value.id
            window.location.replace(`/project/${projectId}`)
        }
        const unsubscribe = () => {
            confirmStore.confirm({
                text: `Вы уверены что хотите отключить подписку?`,
                onConfirm: async () => {
                    unsubscribeLoading.value = true
                    await billingStore.unsubscribeIntegration(project.value.id, currentTariff.value.id)
                    goToProject()
                },
            })
        }

        return {
            tarriffName,
            currentTariffState,
            subscriptionPrice,
            subscriptionState,
            currentTariff,
            subscriptionIsActive,
            openPaymentModal,
            unsubscribe,
        }
    },
})
