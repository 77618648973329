
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import Slider from '../../../components/ui/Slider/Slider.vue'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import Breadcrumbs from '../../../components/Breadcrumbs.vue'
import { useProject } from '../../../hooks/useProject'
import { useMarketplaceStore } from '../../../store/stores/marketplace'
import { useBillingStore } from '@/store/stores/billing'
import useRouter from '../../../hooks/useRouter'
import { useTabs } from '../../../hooks/useTabs'
import Tariffs from '@/modules/marketplace/components/billing/tabsViews/Tariffs.vue'
import { useNotificationStore } from '../../../store/stores/notification'
import TariffIntegrationsTable from '../components/billing/TariffIntegrationsTable.vue'
import TariffStateHeader from '../components/billing/tariffStateHeader/TariffStateHeader.vue'
import PaymentModal from '../components/billing/PaymentModal.vue'
export default defineComponent({
    components: {
        Slider,
        SpinnerLoader,
        Breadcrumbs,
        TariffIntegrationsTable,
        TariffStateHeader,
        PaymentModal,
    },
    props: {},
    setup() {
        const { project } = useProject()
        const { route } = useRouter()
        const notificationStore = useNotificationStore()
        const billingStore = useBillingStore()
        const loading = ref(false)

        const { activeTab, tabs } = useTabs({
            tabs: [
                {
                    tabName: 'ТАРИФЫ',
                    sectionName: 'tariffs',
                    component: Tariffs,
                },
                // закомментировано, так как пока не готовы соответсвующие модули на бекенде
                // {
                //     tabName: 'ИНТЕГРАЦИИ',
                //     sectionName: 'integrations',
                //     component: Tariffs,
                // },
                // {
                //     tabName: 'ИСТОРИЯ ОПЛАТЫ',
                //     sectionName: 'payHistory',
                //     component: Tariffs,
                // },
            ],
            defaultSectionName: 'info',
        })
        const breadcrumbs = computed(() => [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: project.value.name,
                to: `/project/${project.value.id}`,
            },
            {
                text: 'ТАРИФЫ',
                to: `/project/${project.value.id}/billing`,
                disabled: true,
            },
        ])
        const currentTariff = computed(() => billingStore.getCurrentTariff(project.value.id))
        return { loading, breadcrumbs, activeTab, tabs, notificationStore, currentTariff }
    },
})
