
import { computed, defineComponent, ref } from 'vue'
import { useProject } from '../../../../../hooks/useProject'
import { useBillingStore } from '../../../../../store/stores/billing'

export default defineComponent({
    props: {},
    setup(props, { emit }) {
        const { project } = useProject()
        const billingStore = useBillingStore()
        const currentTariffData = computed(() => billingStore.getCurrentTariff(project.value.id))
        const selectedTariff = computed(() => billingStore.getTariffById(billingStore.selectedTariffId!)!)
        const selectedTariffPriceDescription = computed(() => {
            if (billingStore.selectedSubscriptionPeriod === '1') return 'В месяц'
            if (billingStore.selectedSubscriptionPeriod === '3') return 'Раз в 3 месяца'
            if (billingStore.selectedSubscriptionPeriod === '12') return 'В год'
        })
        const currentTariffPriceDescription = computed(() => {
            if (!currentTariffData.value) return ''
            if (currentTariffData.value.subscriptionPeriod === '1') return 'В месяц'
            if (currentTariffData.value.subscriptionPeriod === '3') return 'Раз в 3 месяца'
            if (currentTariffData.value.subscriptionPeriod === '12') return 'В год'
        })
        const currentTariffName = computed(() =>
            currentTariffData.value ? currentTariffData.value.name : 'Нет тарифа'
        )
        const currentTariffState = computed(() => billingStore.getCurrentTariffState(project.value.id))
        const currentTariffFormattedPrice = computed(() => {
            if (!currentTariffData.value) return '0 ₽'
            return billingStore.formatPrice(
                billingStore.getTarrifRegularPrice(
                    currentTariffData.value.id,
                    currentTariffData.value.subscriptionPeriod
                )
            )
        })
        const selectedTariffFormattedPrice = computed(() =>
            billingStore.formatPrice(
                billingStore.getTarrifRegularPrice(
                    selectedTariff.value!.id,
                    billingStore.selectedSubscriptionPeriod!
                )
            )
        )

        const openPaymentModal = () => {
            billingStore.setPaymentModalOpened(true)
        }
        return {
            currentTariffData,
            selectedTariff,
            currentTariffState,
            currentTariffFormattedPrice,
            selectedTariffFormattedPrice,
            selectedTariffPriceDescription,
            currentTariffPriceDescription,
            currentTariffName,
            openPaymentModal,
        }
    },
})
