
import { computed, defineComponent, ref } from 'vue'
import { useBillingStore } from '../../../../store/stores/billing'

export default defineComponent({
    props: {
        tariffId: { type: String, required: true },
        recommended: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const billingStore = useBillingStore()
        const tariff = billingStore.getTariffById(props.tariffId)!
        const price = computed(() =>
            billingStore.getTarrifRegularPrice(props.tariffId, billingStore.selectedSubscriptionPeriod!)
        )
        const formattedPrice = computed(() => billingStore.formatPrice(price.value))
        return { tariff, formattedPrice }
    },
})
